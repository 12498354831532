






























































































































import Vue from 'vue';
import moment from 'moment';
import { message, Modal } from 'ant-design-vue';
import * as api from '@/api/couponDistribute';
import couponDetail from '../couponTemplate/couponDetail/index.vue';
import createDistribute from './createDistribute/index.vue';
import distributeDetail from './distributeDetail/index.vue';
export default Vue.extend({
  name: 'couponDistributeList',
  components: { couponDetail, createDistribute, distributeDetail },
  data() {
    return {
      tableLoading: false,
      searchForm: {
        name: '', // 优惠券名称
        sendStatus: '', // 派发状态
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      couponDistributeColumns: [
        {
          title: '优惠券名称',
          dataIndex: 'name',
          align: 'center',
          fixed: 'left',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '派发人',
          dataIndex: 'recUserName',
          align: 'center',
        },
        {
          title: '派发时间',
          dataIndex: 'sendTime',
          align: 'center',
          scopedSlots: { customRender: 'sendTime' },
        },
        {
          title: '发送范围',
          dataIndex: 'sendRangeInfo',
          align: 'left',
          scopedSlots: { customRender: 'sendRangeInfo' },
        },
        {
          title: '派发数量',
          dataIndex: 'sendCount',
          align: 'center',
          scopedSlots: { customRender: 'sendCount' },
        },
        {
          title: '派发状态',
          dataIndex: 'sendStatus',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'sendStatus' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      couponDistributeData: [] as any,
    };
  },
  mounted() {
    this.getCouponDistributeList();
  },
  methods: {
    moment,
    // 获取优惠券派发列表
    getCouponDistributeList() {
      this.tableLoading = true;
      api
        .getCouponDistributeList(
          this.searchForm.name,
          this.searchForm.sendStatus,
          this.pagination.current,
          this.pagination.pageSize
        )
        .then((res) => {
          if (res.status === 200) {
            this.pagination.total = res.result.total;
            this.couponDistributeData = res.result.list;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 组合查询表单提交
    handleSearchSubmit() {
      this.pagination.current = 1;
      this.getCouponDistributeList();
    },
    // 组合查询表单重置
    handleSearchReset() {
      (this.$refs.searchFormRef as HTMLFormElement).resetFields();
      this.pagination.current = 1;
      this.getCouponDistributeList();
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getCouponDistributeList();
    },
    // 新建事件
    handleCreate() {
      (this.$refs.createDistribute as HTMLFormElement).show();
    },
    // 编辑事件
    handleEdit(record) {
      (this.$refs.createDistribute as HTMLFormElement).edit(record);
    },
    // 详情事件
    handleDetail(record) {
      (this.$refs.distributeDetail as HTMLFormElement).show(record);
    },
    //查询优惠券模板详情
    showCouponDetail(record) {
      (this.$refs.couponDetail as HTMLFormElement).show(
        record.couponDefinitionId
      );
    },
    // 派发事件
    handleDistribute(record) {
      Modal.confirm({
        title: '操作提示',
        content: '确定派发吗？',
        okText: '是的，确定派发！',
        onOk: () => {
          return new Promise((resolve) => {
            api
              .releaseDistributeRecords(record)
              .then((res) => {
                if (res.status === 200) {
                  message.success('操作成功！');
                } else {
                  message.error('操作失败！');
                }
              })
              .finally(() => {
                resolve(true);
                this.handleSearchSubmit();
              });
          });
        },
      });
    },
    // 数据保存或发布成功事件回调
    formSaveSuccess() {
      this.handleSearchSubmit();
    },
  },
});
